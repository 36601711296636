import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Headmeta from "../components/Headmeta"


export default function NotFound() {
  return (
    <Layout>
            <Headmeta importedIn="page unavailable | This page could not be found. Http error 404" />

      <div>
        <Container>
          <Row>
            <Col>
              <h2>404</h2>
              <p>
                You have entered an area you were not supposed to enter. Here
                are some ways we can help you get back on track.
              </p>
              <p><Link to="/">Back to Home</Link></p>
              <p> <Link to="/contact">Contact us</Link> and we'll guide or rescue you from this unknown territory. </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
